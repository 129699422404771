import store from '../../../../src/store/store.js'
const { permissions } = store.state.AppActiveUser
const show_menu = []

const dashboard = {
  url: null,
  name: 'สรุปยอด',
  slug: '',
  icon: 'HomeIcon',
  submenu: [
    {
      url: '/',
      name: 'สรุปยอดรายวัน',
      slug: 'dashboard'
    },
    {
      url: '/month',
      name: 'สรุปยอดเดือน',
      slug: 'month'
    }
  ]
}

// const user = {
//   url: '/member',
//   name: 'ข้อมูลยูสเซอร์',
//   slug: 'member',
//   icon: 'UsersIcon',
//   submenu: [
//     {
//       url: '/membersearch',
//       name: 'ค้นหาข้อมูลยูสเซอร์',
//       slug: 'membersearch'
//     },
//     {
//       url: '/member',
//       name: 'ข้อมูลยูสเซอร์ทั้งหมด',
//       slug: 'member'
//     }
//   ]
// }

// const addcredit = {
//   url: '/agent',
//   name: 'เติม-ถอน เครดิต',
//   slug: 'agent',
//   icon: 'CreditCardIcon'
// }

// const minuscredit = {
//   url: '/agentminus',
//   name: 'ถอน เครดิต',
//   slug: 'agentminus',
//   icon: 'CreditCardIcon'
// }

// const topup = {
//   url: '/topup',
//   name: 'รายการเพิ่มเครดิต',
//   slug: 'topup',
//   icon: 'PlusCircleIcon'
// }

// const withdraw = {
//   url: null,
//   name: 'รายการถอนเงิน',
//   slug: '',
//   icon: 'MinusCircleIcon',
//   submenu: [
//     {
//       url: '/withdraw',
//       name: 'รอทำรายการ',
//       slug: 'withdraw'
//     },
//     {
//       url: '/withdrawwait/error',
//       name: 'ถอนไม่สำเร็จ',
//       slug: 'withdrawerror'
//     },
//     {
//       url: '/withdrawlist',
//       name: 'ประวัติทำรายการ',
//       slug: 'withdrawlist'
//     }
//   ]
// }

// const truewallet = {
//   url: '/wallet',
//   name: 'รายการ Truewallet',
//   slug: 'wallet',
//   icon: 'ListIcon'
// }

// const bank = {
//   url: '/bank',
//   name: 'รายการ ธนาคาร',
//   slug: 'bank',
//   icon: 'DollarSignIcon'
// }

// const setting = {
//   url: null,
//   name: 'ตั้งค่าระบบ',
//   slug: '',
//   icon: 'SettingsIcon',
//   submenu: [
//     {
//       url: '/SettingSystem',
//       name: 'ระบบ หน้าเว็บ',
//       slug: 'settingsystem'
//     },
//     {
//       url: '/SettingWithdraw',
//       name: 'ระบบ ถอนเงิน',
//       slug: 'settingwithdraw'
//     },
//     {
//       url: '/SettingPopup',
//       name: 'ระบบ แจ้งเตือน',
//       slug: 'popupsetting'
//     },
//     {
//       url: '/SettingRanking',
//       name: 'ระบบ Ranking',
//       slug: 'SettingRanking'
//     },
//     {
//       url: null,
//       name: 'ระบบ บัญชี',
//       slug: 'Acc',
//       submenu: [
//         {
//           url: '/SettingWalletDeposit',
//           name: 'บัญชีฝาก',
//           slug: 'settingwalletdeposit'
//         },
//         {
//           url: '/SettingWalletWithdraw',
//           name: 'บัญชีถอน',
//           slug: 'settingwalletwithdraw'
//         },
//         {
//           url: '/SettingTruewallet',
//           name: 'บัญชี Truewallet',
//           slug: 'settingtruewallet'
//         }
//       ]
//     }
//   ]
// }

// const report = {
//   url: null,
//   name: 'รีพอร์ท',
//   slug: 'report',
//   icon: 'DownloadIcon',
//   submenu: [
//     {
//       url: process.env.VUE_APP_TITLE === 'WOWBET88' ? '/reportbonus' : '/reportpromotion',
//       name: 'โบนัส(ใหม่)',
//       slug: process.env.VUE_APP_TITLE === 'WOWBET88' ? 'reportbonus' : 'reportpromotion'
//     },
//     {
//       url: '/reportaff',
//       name: 'แนะนำเพื่อน',
//       slug: 'reportaff'
//     },
//     {
//       url: '/reportturn',
//       name: 'รับเงินคืน',
//       slug: 'reportturn'
//     },
//     {
//       url: '/reportregister',
//       name: 'สมัคร / เติมเงิน',
//       slug: 'reportregister'
//     },
//     {
//       url: '/reportminigame',
//       name: 'เปิดไพ่ / กงล้อ',
//       slug: 'reportminigame'
//     },
//     {
//       url: '/reportlog',
//       name: 'ประวัติการใช้งาน',
//       slug: 'reportlog'
//     },
//     {
//       url: '/reportactiveuser',
//       name: 'Activeuser',
//       slug: 'reportactiveuser'
//     },
//     {
//       url: '/winloss',
//       name: 'สรุปยอดสายบน',
//       slug: 'winloss'
//     },
//     {
//       url: '/ReportTournament',
//       name: 'เกาจิ้งเมืองไทย',
//       slug: 'Tournament'
//     }
//   ]
// }

// const admin = {
//   url: '/settingstaff',
//   name: 'ระบบ พนักงาน',
//   slug: 'settingstaff',
//   icon: 'SettingsIcon'
// }

// const creditfree = {
//   url: null,
//   name: 'รายการเครดิตฟรี',
//   slug: '',
//   icon: 'CreditCardIcon',
//   submenu: [
//     {
//       url: '/creditfreewait',
//       name: 'รอทำรายการ',
//       slug: 'creditfreewait'
//     },
//     {
//       url: '/creditfreelist',
//       name: 'รายการเครดิตฟรี',
//       slug: 'creditfreelist'
//     },
//     {
//       url: '/creditfreeinfo',
//       name: 'แก้ไขลิงค์',
//       slug: 'creditfreeinfo'
//     }
//   ]
// }

// const review = {
//   url: null,
//   name: 'เพิ่ม-ลด รูปภาพ',
//   slug: '',
//   icon: 'ListIcon',
//   submenu: [
//     {
//       url: '/review',
//       name: 'คำชม',
//       slug: 'review'
//     },
//     {
//       url: '/jackpot',
//       name: 'Jackpot',
//       slug: 'jackpot'
//     }

//   ]
// }

// const audit = {
//   url: null,
//   name: 'ตรวจสอบบัญชีถอน',
//   slug: '',
//   icon: 'CreditCardIcon',
//   submenu: [
//     {
//       url: '/checkbankwithdraw/account',
//       name: 'รายการบัญชีถอน',
//       slug: 'checkbankwithdraw/account'
//     },
//     {
//       url: '/checkbankwithdraw/adjustbalance',
//       name: 'เติม เครดิต',
//       slug: 'checkbankwithdraw/adjustbalance'
//     },
//     {
//       url: '/checkbankwithdraw/adjustwithdraw',
//       name: 'เบิก เครดิต',
//       slug: 'checkbankwithdraw/account'
//     },
//     {
//       url: '/checkbankwithdraw/upslip',
//       name: 'อัพสลิป',
//       slug: 'checkbankwithdraw/upslip'
//     }
//   ]
// }

// const auditDeposit = {
//   url: '/bankcompare',
//   name: 'ตรวจสอบบัญชีฝาก',
//   slug: 'bankcompare',
//   icon: 'DollarSignIcon'
// }

// const accountManagement = {
//   url: null,
//   name: 'จัดการบัญชีภายใน',
//   slug: '',
//   icon: 'CreditCardIcon',
//   submenu: [
//     {
//       url: '/internal-transfer/manual',
//       name: 'โยกเงินบัญชีภายใน',
//       slug: 'internal-transfer/manual'
//     },
//     {
//       url: '/internal-transfer/logs',
//       name: 'ประวัติการโยกเงิน',
//       slug: '/internal-transfer/logs'
//     }
//   ]
// }


// const temporary = {
//   url: null,
//   name: 'ตั้งค่าบัญชีพัก',
//   slug: '',
//   icon: 'SettingsIcon',
//   submenu: [
//     {
//       url: '/SettingTemporary',
//       name: 'รายการบัญชีพัก',
//       slug: 'SettingTemporary'
//     }
//   ]
// }

// const agent = {
//   url: '/games',
//   name: 'Agent ตัวเกม',
//   slug: '',
//   icon: 'PlayIcon',
//   target: '_blank'
// }

// Check permission and add menus
if (permissions) {
  if (permissions.dashboard && permissions.dashboard.read) show_menu.push(dashboard)
  // if (permissions.user && permissions.user.read) show_menu.push(user)
  // if (permissions.addcredit && permissions.addcredit.action) show_menu.push(addcredit) // Action only
  // if (permissions.minuscredit && permissions.minuscredit.action) show_menu.push(minuscredit) // Action only
  // if (permissions.topup && permissions.topup.read) show_menu.push(topup)
  // if (permissions.withdraw && permissions.withdraw.read) show_menu.push(withdraw)
  // if (permissions.truewallet && permissions.truewallet.read) show_menu.push(truewallet)
  // if (permissions.bank && permissions.bank.read) show_menu.push(bank)
  // if (permissions.setting && permissions.setting.read) show_menu.push(setting)
  // if (permissions.report && permissions.report.read) show_menu.push(report)
  // if (permissions.admin && permissions.admin.read) show_menu.push(admin)
  // if (permissions.creditfree && permissions.creditfree.read) show_menu.push(creditfree)
  // if (permissions.review && permissions.review.read) {
  //   show_menu.push(review)
  // }
  // if (permissions.audit && permissions.audit.read) {
  //   show_menu.push(audit)
  //   show_menu.push(auditDeposit)
  // }
  // if (permissions.accountManagement && permissions.accountManagement.read) show_menu.push(accountManagement)
  // if (permissions.temporary && permissions.temporary.read) show_menu.push(temporary)

}

// Add
// show_menu.push(agent)

export default show_menu

<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.photoURL">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.name }}</p>
      <small>Administrator</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <!-- <li
            class="flex py-2 pl-4 cursor-pointer hover:bg-primary hover:text-white">

            <router-link to="/profile" class="text-white">
            <feather-icon icon="ShieldIcon" svgClasses="w-4 h-4" /> -->
             <!-- <router-link to="/profile" class="ml-2">เปิดใช้งาน 2FA</router-link> -->
            <!-- <span to="/profile" class="ml-2">เปิดใช้งาน 2FA</span>
            </router-link>
          </li> -->
          <li
            class="flex py-2 pl-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">ออกจากระบบ</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  data () {
    return {
      userInfo: {}
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  async mounted () {
    await axios
      .get('https://dash.api-ruaygame168.com/auth/profile')
      .then(response => (this.userInfo = response.data))
      .catch(console.error)

    this.$store.commit('UPDATE_USER_INFO', {
      uid: this.userInfo.admin_id,
      username: this.userInfo.admin_user,
      name: this.userInfo.admin_name,
      lv: this.userInfo.admin_level,
      permissions: this.userInfo.admin_permissions
    }, {
      root: true
    })
  },
  methods: {
    logout () {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('accessToken')
      // This is just for demo Purpose. If user clicks on logout -> redirect
      //this.$router.push('/Login').catch(() => {})
      window.location.reload('/Login')
    }
  }
}
</script>
